header {
  height: 5.1875rem;
}

body {
  background-color: #E7DFD8;
}

a.button.active {
  border: 1px solid var(--fore-color);
}

.MuiTypography-body1 {
  font-size: 1.2rem;
}
